import { UseQueryResult } from '@tanstack/react-query';
import { useEffect } from 'react';

import { useLatest } from './useLatest';

type QueryEvents<RespT, ErrT> = {
  onSuccess: (resp: RespT) => any;
  onError: (resp: ErrT) => any;
};

export function useQueryEvents<RespT, ErrT>(
  query: UseQueryResult<RespT, ErrT>,
  { onError, onSuccess }: Partial<QueryEvents<RespT, ErrT>>
) {
  const handleSuccessRef = useLatest(onSuccess);
  const handleErrorRef = useLatest(onError);

  useEffect(() => {
    if (query.data) {
      handleSuccessRef.current?.(query.data);
    }
  }, [query.data]);

  useEffect(() => {
    if (query.error) {
      handleErrorRef.current?.(query.error);
    }
  }, [query.error]);
}
